<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 砍价订单
 * @Date: 2020-12-29 15:17:01
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-05 14:31:31
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/Bargaining.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="type" @click="tabkey">
      <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
    </van-tabs>
    <div v-if="!list.length" class="ordernull">
      <van-empty
        image="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/23/yuelvhui680UxFT6uf1608649312.png"
        description="暂无数据"
      />
    </div>
    <div v-else />
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Tab, Tabs } from 'vant'

Vue.use(Tab).use(Tabs).use(Empty)
import {
  myBargainLists
} from '@/services/userApi'

export default {
  data() {
    return {
      uid: '',
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      list: [],
      tablist: [
        { name: '全部' },
        { name: '进行中' },
        { name: '结束' }
      ]
    }
  },
  created() {
    this.uid = localStorage.getItem('uid')
    this.getinfo()
  },
  methods: {
    getinfo() {
      const prom = {
        fromType: 2,
        page: this.page,
        pageSize: this.pageSize,
        uid: this.uid,
        type: this.type
      }
      myBargainLists(prom).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },
    tabkey() {
      this.getinfo()
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .ordernull {
    padding: 13px;
  }
}
</style>
